import React from 'react';
import {Wrapper, Metamask, LoginButton, ErrorWrapper, Link} from './styles';
import MetamaskImg from './assets/MetaMask.png';
import {Redirect, useHistory} from 'react-router-dom';
import {SetterOrUpdater} from 'recoil';
import {Modal} from '../Modal';
import {errors} from '../../constants/errors';
import {route} from '../../constants/route';
import {checkMetaMask} from '../../helper/metamask';

interface LoginProps {
  setError: SetterOrUpdater<string | null>;
  errorState: string | null;
  activeAddress: string | null;
  requestAccounts: () => Promise<string>;
  treatmentChains: () => Promise<boolean>;
}

export const Login: React.FC<LoginProps> = ({
  setError,
  errorState,
  activeAddress,
  requestAccounts,
  treatmentChains,
}) => {
  const history = useHistory();
  const handlerLogin = async () => {
    const error = await requestAccounts();
    setError(error);
    if (error.length === 0) {
      history.push(route.base);
    }
  };
  return (
    <>
      {errorState === errors.chain && <Modal setError={setError} treatmentChains={treatmentChains} />}
      {activeAddress && activeAddress.length > 0 && <Redirect to={route.base} />}
      <Wrapper>
        <Metamask src={MetamaskImg} />
        {checkMetaMask() ? (
          <LoginButton type={'button'} value={'Login with MetaMask'} onClick={handlerLogin} />
        ) : (
          <Link href={'https://metamask.io/'} target={'_blank'} rel="noreferrer">
            <LoginButton type={'button'} value={'Install MetaMask'} />
          </Link>
        )}
      </Wrapper>
      <ErrorWrapper theme={{status: errorState && errorState.length > 0}}>{errorState}</ErrorWrapper>
    </>
  );
};
