import React from 'react';
import {StyledText} from './styles';

type variantType = 'primary' | 'secondary';
type weightType = 'regular' | 'medium' | 'semi-bold' | 'bold';
type decorationType = 'underline' | 'none';
type caseType = 'uppercase' | 'lowercase' | 'capitalize';
type TextColor = 'white' | 'blue' | 'black';
type TextAlign = 'left' | 'center' | 'right';

export type TextBaseProps = {
  variant?: variantType;
  weight?: weightType;
  size?: number;
  decoration?: decorationType;
  case?: caseType;
  color?: TextColor | string;
  className?: string;
  width?: string;
  align?: TextAlign;
};

export type TextProps = TextBaseProps & {as?: keyof JSX.IntrinsicElements};

export const Text: React.FC<TextProps> = StyledText;
