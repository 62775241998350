import styled from 'styled-components';
import {Text} from '../Typography/Text';
import {ReactComponent as SwapImage} from './assets/images/swapArrows.svg';
import {Link} from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 50px;
  width: 90%;
`;

export const NetworkBlock = styled.div`
  position: relative;
  border-radius: 10px;
  background-color: rgb(39, 63, 82);
  width: 476px;
  padding: 21px 57px 53px 51px;
  height: 301px;
  @media (max-width: 1465px) {
    width: 100%;
    min-width: 300px;
  }
`;

export const SwapBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 205px;
  background-color: rgb(45, 69, 87);
  @media (max-width: 1465px) {
    min-width: 100px;
    border: 1px solid rgb(45, 69, 87);
  }
`;

export const SwapButton = styled(SwapImage)<{$isDisabled?: boolean; $margin?: boolean}>`
  cursor: pointer;
  transition: transform 0.5s;
  &:hover {
    ${({$isDisabled}) => ($isDisabled ? '' : 'transform: rotate(-180deg);')}
  }
  path {
    ${({$isDisabled}) => ($isDisabled ? 'fill: darkgray' : '')}
  }
  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
  ${({$margin}) => ($margin ? '' : 'margin-bottom: 20px;')}
`;

export const LoadingButton = styled(SwapImage)`
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
`;

export const Title = styled(Text).attrs({
  size: 24,
})`
  margin-top: 21px;
`;

export const TitleCentre = styled(Text).attrs({
  size: 24,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Line = styled.div`
  width: 554px;
  left: 17px;
  top: 62px;
  height: 1px;
  position: absolute;
  background: rgba(56, 99, 133, 0.66);
  @media (max-width: 1465px) {
    width: 90%;
    left: 5%;
  }
`;

export const NetworkRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 39px;
`;

export const NetworkImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 69px;
  width: 69px;
  border-radius: 50%;
  background-color: white;
  margin-right: 18px;
  @media (max-width: 1300px) {
    background: none;
    margin-right: 10px;
  }
`;

export const WaterwallIcon = styled.img`
  width: 44px;
  height: 40px;
`;

export const InputWrapper = styled.div`
  display: flex;
  padding: 0px 31px 0px 31px;
  background-color: #213748;
  justify-content: space-between;
  width: calc(100% - 62px);
  height: 86px;
  margin-top: 29px;
  align-items: center;
  border-radius: 50px;
  @media (max-width: 1465px) {
    padding: 0 5% 0 5%;
    width: 90%;
  }
`;

export const Input = styled.input`
  box-sizing: border-box;
  height: 34px;
  background-color: #213748;
  width: 50%;
  outline: none;
  color: #fff;
  margin: 0;
  border: 0;
  display: flex;
  font-size: 24px;
  align-items: center;
  font-family: Roboto, sans-serif;
  &::placeholder {
    size: 18px;
    color: #fff;
  }
`;

export const TransferButton = styled.input`
  display: flex;
  align-items: center;
  background-color: #008eee;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  padding: 31px 55px;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 10px;
  border: none;
  margin-bottom: 75px;
  transition: background-color 0.5s ease;
  outline: none;

  &:hover {
    background-color: #00b8ee;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 388px;
  height: 52px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: calc(100% - 39px);
  align-items: center;
  height: 52px;
  padding: 0 15px 0 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  background: rgba(48, 76, 98, 1);
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  z-index: 2;
  color: #fff;
  font-size: 20px;
  border: none;
  font-weight: 400;
`;

export const OptionsNetWrapper = styled.div`
  display: ${(props) => (props.theme.status ? 'flex' : 'none')};
  position: absolute;
  background: rgba(44, 70, 91, 0.59);
  border-radius: 20px;
  flex-direction: column;
  padding-top: 37px;
  top: 26px;
  width: 100%;
  z-index: 1;
  & > div:last-child {
    &:hover {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: 15px;
  width: calc(100% - 24px);
  line-height: 22px;
  font-weight: 400;
  height: 100%;
  padding-left: 24px;
  padding-bottom: 7px;
  &:hover {
    background: rgba(44, 70, 91, 1);
  }
`;

export const SelectToken = styled.div`
  position: relative;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  height: 45px;
  width: auto;
  min-width: 118px;
  max-width: 150px;
  padding-left: 5px;
  background-color: rgb(39, 63, 82);
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  display: ${(props) => (props.theme.status ? 'flex' : 'none')};
  background-color: rgb(45, 69, 87);
  width: 100%;
  min-width: 118px;
  max-width: 150px;
  max-height: 300px;
  flex-direction: column;
  top: 45px;
  left: 0;
  overflow-y: auto;
`;

export const OptionToken = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 13px;
  border: none;
  font-weight: 400;
  align-items: center;
  min-height: 45px;
  min-width: 118px;
  max-width: 150px;
  &:hover {
    background-color: rgb(30, 62, 82);
  }
`;

export const OptionImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 9px;
`;

export const ViewModeTokenImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

export const DownArrow = styled.img`
  width: 15px;
  height: 8px;
  margin-left: 16px;
  margin-right: 5px;
  transform: ${(props) => (props.theme.status ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const BalanceWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  text-align: left;
  width: 100%;
  & > span {
    margin-right: 5px;
  }
`;

export const ErrorTransferWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-align: left;
  justify-content: flex-start;
  margin-top: 5px;
  color: rgb(255, 255, 255);
  padding: 5px 5%;
  width: 90%;
  background: rgb(25, 47, 64);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
`;

export const LinkToHash = styled.a`
  margin-left: 10px;
  text-decoration: none;
  text-underline: none;
`;

export const TopBlock = styled.div`
  width: 600px;
  margin-bottom: 40px;
`;

export const BackButton = styled.button`
  padding: 10px 10px 10px 0;
  background: none;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  top: 90px;
  left: 60px;
  cursor: pointer;
  border: none;
  :hover {
    & span {
      text-decoration: underline;
    }
  }

  & > span:first-child {
    position: relative;
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      width: 8px;
      height: 8px;
      border-top: 1px rgba(255, 255, 255, 0.87) solid;
      border-left: 1px rgba(255, 255, 255, 0.87) solid;
      transform: rotate(-45deg) translateY(-1px);
    }
  }

  @media (max-width: 1200px) {
    left: 20px;
  }

  @media (max-width: 650px) {
    position: fixed;
    padding: 10px;
    background-color: #102636;
    top: 87vh;
    padding-left: 20px;
  }
`;

export const ViewModeAmountWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const SimpleLink = styled(Link)`
  text-decoration: none;
  &:hover {
    opacity: 0.65;
  }
`;

export const ScaleWrapper = styled.div`
  transform: scale(-1, 1);
`;
