import {ChangeEventHandler, useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {address, chainId, error} from '../states/session';
import {networksNames, networksTags, tagToChainId} from '../constants/networks';
import Web3 from 'web3';
import {NETWORKS, EXCHANGES, TOKENS} from '../constants/env';
import {getBalance, sendTransaction} from './metamask';
import {PassTransactionType} from '../types/transaction';
import {validateBalance} from '../helper/validation';
import {useParams} from 'react-router';
import {errors} from '../constants/errors';
import {useHistory} from 'react-router-dom';
import {route} from '../constants/route';

export const useNativeTransfer = () => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  const {slug} = useParams<{slug: string}>();
  const currentChainId = useRecoilValue(chainId);
  const history = useHistory();
  const [errorState, setError] = useRecoilState(error);
  const [balance, setBalance] = useState<string>('');
  const userAddress = useRecoilValue(address);
  const correctNet = currentChainId || '';
  const translationtNet = tagToChainId[slug]?.find((el) => el === NETWORKS?.find((net) => net.chainId === el)?.chainId);
  const [amount, setValue] = useState<string>('0.00');
  const [transferValid, setValidTransfer] = useState<boolean>(false);
  const [networkIsCorrect, setCorrectStatus] = useState<boolean>(currentChainId === translationtNet);
  const [transferError, setTransferError] = useState<string>('');
  const [viewTransactionMode, setViewMode] = useState<boolean>(false);
  const [passTransaction, setPassTransaction] = useState<PassTransactionType | null>(null);
  const [requestTrigger, setRequestTrigger] = useState<boolean>(false);
  const [confirmations, setConfirmations] = useState<number>(0);
  const handlerChangeAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
    //add check comissiant here
    if (/^\d+$/.test(e.target.value.replace('.', '')) || e.target.value === '') {
      setValue(e.target.value);
    }
  };
  const treatmentChains = () => {
    try {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: translationtNet}],
      });
    } catch (switchError: any) {
      if (switchError?.code === 4902) {
        try {
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [NETWORKS?.find((el) => el.chainId === translationtNet)],
          });
        } catch (error) {
          setError(error as any);
        }
      }
    }
  };
  const changeBalance = async (address: string) => {
    setBalance('0');
    if (correctNet === translationtNet) {
      const balance = await getBalance(address, null);
      setBalance(balance);
    }
  };
  const closeViewMode = () => {
    setPassTransaction(null);
    setValue('0.00');
    setConfirmations(0);
    setViewMode(false);
  };
  const transfer = async () => {
    if (userAddress && currentChainId) {
      const exchange = EXCHANGES?.find((el) => el.chainId === translationtNet)?.address || '';
      const result = await sendTransaction(
        {
          to: exchange,
          from: userAddress,
          value: web3.utils.toHex(web3.utils.toWei(amount)),
          chainId: correctNet,
        },
        setError,
      );
      if (typeof result === 'string') {
        setPassTransaction({
          sendHash: result,
          sendNetwork: currentChainId,
          receiveNetwork: translationtNet,
          receiveHash: '',
        });
        setViewMode(true);
      }
    }
  };
  useEffect(() => {
    setViewMode(false);
    setValue('0.00');
    if (userAddress && correctNet) {
      changeBalance(userAddress);
    }
    if (currentChainId === translationtNet) {
      setCorrectStatus(true);
      setError('');
    } else {
      setCorrectStatus(false);
      setError(errors.incorrectChain);
    }
  }, [userAddress, correctNet, currentChainId, slug]);
  useEffect(() => {
    validateBalance(amount, balance, setValidTransfer, setTransferError);
  }, [balance, amount]);
  useEffect(() => {
    if (viewTransactionMode) {
      setTimeout(async () => {
        if (history.location.pathname.includes(route.replenish.replace(':slug', ''))) {
          if (passTransaction?.sendHash && passTransaction?.sendHash.length > 0) {
            const transaction = await web3.eth.getTransactionReceipt(passTransaction?.sendHash);
            const currentBlock = await web3.eth.getBlockNumber();
            const confirmationCount = transaction?.blockNumber ? currentBlock - transaction.blockNumber : 0;
            confirmationCount && confirmationCount !== confirmations ? setConfirmations(confirmationCount + 1) : null;
          }
          setRequestTrigger(!requestTrigger);
        }
      }, 10000);
    }
  }, [viewTransactionMode, requestTrigger]);
  return {
    firstNetwork: NETWORKS?.find((el) => el.chainId === translationtNet),
    exchangeName: networksNames[slug],
    errorState,
    handlerChangeAmount,
    amount,
    transfer,
    transferError,
    closeViewMode,
    viewTransactionMode,
    passTransaction,
    balance,
    selectToken: TOKENS?.find((el) => el.symbol === networksTags[slug]),
    transferValid,
    confirmations,
    treatmentChains,
    networkIsCorrect,
    slug,
  };
};
