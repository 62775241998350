import React from 'react';
import {Logo} from '../Logo';
import {
  Container,
  Filling,
  Wrapper,
  HeaderLink,
  Navbar,
  AddressWrapper,
  OptionsNetWrapper,
  Option,
  SelectWrapper,
  SelectBlock,
  DownArrow,
} from './styles';
import {Text} from '../Typography/Text';
import {BalancesType} from '../../types/session';
import ArrowDownNet from '../Home/assets/images/arrow-down-net.png';
import {networksNames, networksTags} from '../../constants/networks';
import {useHistory} from 'react-router-dom';
import {route} from '../../constants/route';
import {DECIMALS} from '../../constants/env';

interface HeaderProps {
  userAddress: string | null;
  balances: BalancesType[] | null;
  menuStatus: boolean;
  setMenuStatus: React.Dispatch<React.SetStateAction<boolean>>;
  menuRef: React.RefObject<HTMLHeadingElement>;
}
export const Header: React.FC<HeaderProps> = ({
  children,
  userAddress,
  balances,
  menuStatus,
  setMenuStatus,
  menuRef,
}) => {
  const history = useHistory();
  const handlerOpenMenu = () => {
    setMenuStatus(!menuStatus);
  };
  const handlerRedirectToExchange = (tag: string | undefined) => () => {
    if (tag) {
      history.push(route.replenish.replace(':slug', tag));
    }
  };
  return (
    <Wrapper>
      <Container>
        <Navbar>
          <HeaderLink to={''}>
            <Logo />
            <Filling>{children}</Filling>
          </HeaderLink>
        </Navbar>
        <>
          {userAddress && balances && (
            <SelectWrapper ref={menuRef}>
              <SelectBlock onClick={handlerOpenMenu}>
                <Text>Replenishment of the exchange</Text>
                <DownArrow src={ArrowDownNet} theme={{status: menuStatus}} />
              </SelectBlock>
              <OptionsNetWrapper theme={{status: menuStatus}}>
                {balances.map((el) => (
                  <Option key={el.network} onClick={handlerRedirectToExchange(el?.network)}>
                    {el?.network ? networksNames[el?.network] : ''}
                    <div>
                      {(Number(el.balance) / DECIMALS).toFixed(8)}
                      {' ' + (el?.network ? networksTags[el.network] : '')}
                    </div>
                  </Option>
                ))}
              </OptionsNetWrapper>
            </SelectWrapper>
          )}
          {userAddress && (
            <AddressWrapper>
              <Text>{userAddress}</Text>
            </AddressWrapper>
          )}
        </>
      </Container>
    </Wrapper>
  );
};
