import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {LoginContainer} from './containers/Login';
import {HomePageContainer} from './containers/HomePage';
import {ReplenishmentPageContainer} from './containers/ReplenishmentPage';

const PublicRoutes = [
  <Route key="login" path="/login" exact component={LoginContainer} />,
  <Route key="home" path="/" exact component={HomePageContainer} />,
  <Route key="Replenish" path="/replenishment-exchange/:slug" exact component={ReplenishmentPageContainer} />,
];

const Routes: React.FC = () => {
  return <Switch>{PublicRoutes}</Switch>;
};

export default Routes;
