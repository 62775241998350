import React, {useEffect, useState} from 'react';
import {checkMetaMask, checkAllowedChain} from '../helper/metamask';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {address, session, error, balances, chainId} from '../states/session';
import {useHistory} from 'react-router-dom';
import {route} from '../constants/route';
import {errors} from '../constants/errors';
import {getAccounts, checkPermission, getTransactionData} from '../hooks/metamask';
import {BALANCE_DELAY, API_URL} from '../constants/env';
import {BalanceResponse} from '../types/metamask';
import {networks} from '../constants/networks';

declare global {
  interface Window {
    ethereum: any;
  }
}

const MetamaskProvider: React.FC = ({children}) => {
  const [sessionState, setSession] = useRecoilState(session);
  const [userAddress, setAddress] = useRecoilState(address);
  const setChain = useSetRecoilState(chainId);
  const setError = useSetRecoilState(error);
  const [balancesState, setBalances] = useRecoilState(balances);
  const history = useHistory();
  const [requestTrigger, setRequestTrigger] = useState<boolean>(false);

  const handlerAccount = async (accounts: Array<string>) => {
    if (await checkPermission()) {
      if (accounts.length !== 0) {
        setAddress(accounts[0] || '');
        if (typeof accounts[0] === 'string') {
          const newAddress = accounts[0];
          networks.map((el) => {
            setBalances([
              {
                balance: '0',
                network: el,
                address: newAddress,
              },
            ]);
          });
        }
        setError('');
      }
    } else {
      setAddress(accounts[0] || '');
      setError('');
      history.push(route.login);
    }
  };
  const getBalances = () => {
    if (balancesState) {
      for (let i = 0; i < balancesState.length; i++) {
        const link = API_URL + `/account/${balancesState[i]?.network}/` + userAddress + '/balance';
        getTransactionData(link).then((res: BalanceResponse | string) => {
          if (typeof res !== 'string') {
            if (res.balance && balancesState[i]?.network && userAddress) {
              setBalances([
                {
                  balance: res.balance,
                  network: balancesState[i]?.network,
                  address: userAddress,
                },
              ]);
            }
          }
        });
      }
    }
  };
  // change after choose all network for work
  const handlerChain = async (chainId: string) => {
    if (!checkAllowedChain(chainId)) {
      setSession({error: errors.chain, address: '', chainId: '', balances: sessionState?.balances || null});
      history.push(route.login);
    } else {
      const account = await getAccounts();
      setChain(chainId);
      if (account.length > 0) {
        if (history.location.pathname.includes(route.replenish.replace(':slug', ''))) {
          return;
        }
        history.push(route.base);
      } else {
        history.push(route.login);
      }
    }
  };

  useEffect(() => {
    if (checkMetaMask()) {
      window.ethereum.on('accountsChanged', handlerAccount);
      window.ethereum.on('chainChanged', handlerChain);
    }
  }, []);

  useEffect(() => {
    if (balancesState && userAddress) {
      getBalances();
    }
  }, [userAddress]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (balancesState && userAddress) {
      id = setTimeout(async () => {
        getBalances();
        setRequestTrigger(!requestTrigger);
      }, BALANCE_DELAY);
    }

    return () => {
      clearTimeout(id);
    };
  }, [requestTrigger]);

  return <>{children}</>;
};

export default MetamaskProvider;
