import React from 'react';
import {ReplenishmentPage} from '../../components/ReplenishmentPage';
import {useNativeTransfer} from '../../hooks/exchangeTransfer';

export const ReplenishmentPageContainer = () => {
  const {
    amount,
    handlerChangeAmount,
    firstNetwork,
    transfer,
    errorState,
    closeViewMode,
    transferValid,
    viewTransactionMode,
    passTransaction,
    transferError,
    selectToken,
    confirmations,
    exchangeName,
    treatmentChains,
    networkIsCorrect,
    slug,
    balance,
  } = useNativeTransfer();
  if (!firstNetwork || !selectToken) return null;
  return (
    <ReplenishmentPage
      treatmentChains={treatmentChains}
      balance={balance}
      exchangeName={exchangeName}
      confirmations={confirmations}
      transferValid={transferValid}
      transfer={transfer}
      errorState={errorState}
      selectToken={selectToken}
      amount={amount}
      changeAmount={handlerChangeAmount}
      closeViewMode={closeViewMode}
      transferError={transferError}
      firstNetwork={firstNetwork}
      viewTransactionMode={viewTransactionMode}
      passTransaction={passTransaction}
      networkIsCorrect={networkIsCorrect}
      slug={slug}
    />
  );
};
