import {ProviderRpcError, RequestedPermissions, TokenType} from '../types/metamask';
import {TransactionType} from '../types/transaction';
import Web3 from 'web3';
import * as contract from '../constants/contract.json';
import {AbiItem} from 'web3-utils';
import {SetterOrUpdater} from 'recoil';
import {DECIMALS} from '../constants/env';

export const checkPermission = async (): Promise<boolean> => {
  return await window.ethereum
    .request({
      method: 'wallet_getPermissions',
    })
    .then((permissions: [] | Array<RequestedPermissions>) => {
      if (permissions.length > 0) {
        return true;
      }
    })
    .catch((error: ProviderRpcError) => {
      console.error(error);
      return false;
    });
};

export const checkChain = async (): Promise<string | undefined> => {
  return (await window.ethereum?.request({method: 'eth_chainId'})) || undefined;
};

export const getAccounts = async (): Promise<string> => {
  if (await checkPermission()) {
    const accounts = await window.ethereum.request({method: 'eth_accounts'});
    return accounts.length > 0 ? accounts[0] : '';
  }
  return '';
};

export const getBalance = async (address: string, selectToken: TokenType | null) => {
  let balance: string;
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  if (selectToken?.isNative) {
    const result = await web3.eth.getBalance(address);
    balance = web3.utils.fromWei(web3.utils.toBN(result).toString());
    return (Math.round(Number(balance) * 100000000) / 100000000).toString();
  }
  if (selectToken) {
    //: delete selectToken.tokenAddress.length > 0 when in waterwall append tokenAddress and delete window.eth..
    //if (selectToken.tokenAddress.length > 0 && selectToken.chainId.includes(currentChainId)) {
    const mainContract = JSON.parse(JSON.stringify(contract)).default;
    const myContract = new web3.eth.Contract(mainContract as AbiItem[], selectToken.tokenAddress);
    try {
      const result = await myContract.methods.balanceOf(address).call();
      balance = web3.utils.fromWei(web3.utils.toBN(result).toString(), 'ether');
    } catch (e) {
      balance = '0';
    }
    return (Math.round(Number(balance) * DECIMALS) / DECIMALS).toString();
  } else {
    const result = await window.ethereum.request({
      method: 'eth_getBalance',
      params: [address, 'latest'],
    });
    balance = web3.utils.fromWei(web3.utils.toBN(result).toString());
    return (Math.round(Number(balance) * 100000000) / 100000000).toString();
  }
};

export const gasAssessmentContract = async (
  data: string,
  sender: string,
  contractAddress: string,
  chainId: string,
  to: string,
  setError: SetterOrUpdater<string | null>,
) => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  const gasPrice = await web3.eth.getGasPrice();
  const gasUsed = await web3.eth.estimateGas(
    {
      to: contractAddress,
      from: sender,
      data: data,
    },
    function (error, gas) {
      if (error) {
        setError('Gas required exceeds allowance');
      }
      return gas;
    },
  );
  const totalFee = Number(web3.utils.fromWei(gasPrice, 'ether')) * gasUsed;
  return {
    totalFee: Math.round(totalFee * 1000000) / 1000000,
    Data: data,
    RawData: {
      to: to,
      data: data,
      gasPrice: gasPrice,
      gasLimit: gasUsed,
      chainId: Number(chainId),
    },
  };
};

export const gasAssessment = async (sender: string, contractAddress: string) => {
  const web3 = new Web3(Web3.givenProvider);
  const gasPrice = await web3.eth.getGasPrice();
  const gasUsed = await web3.eth.estimateGas(
    {
      to: contractAddress,
      from: sender,
    },
    function (error: any, gas: any) {
      if (error) {
        console.log(error);
        return;
      }
      return gas;
    },
  );
  const totalFee = Number(web3.utils.fromWei(gasPrice, 'ether')) * gasUsed;
  return {
    totalFee: totalFee,
    gasPrice,
    gas: gasUsed,
  };
};

export const sendTransaction = async (
  transactionParameters: TransactionType,
  setError: SetterOrUpdater<string | null>,
) => {
  let data;
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    data = txHash;
  } catch (e) {
    setError((e as any).message);
  }
  return data;
};

export const switchNetwork = async (chainId: string) => {
  let statusTransaction;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{chainId: chainId}],
    });
    statusTransaction = true;
  } catch (switchError: any) {
    statusTransaction = false;
  }
  return statusTransaction;
};

export const getTransactionData = async (link: string) => {
  try {
    const response = await fetch(link, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    return await response.json();
  } catch (e) {
    return '';
  }
};
