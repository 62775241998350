import Eth from '../components/Home/assets/images/ethernet.png';
import Waterwall from '../components/Home/assets/images/waterwall.png';
import Bnb from '../components/Home/assets/images/bnb.png';
import {RequestTagType} from '../types/transaction';

export const networkIcons = [
  {
    image: Eth,
    chainId: ['0x5', '0x1', '0xaa36a7'],
  },
  {
    image: Waterwall,
    chainId: ['0x833e40', '0x833e3f'],
  },
  {
    image: Bnb,
    chainId: ['0x61', '0x38'],
  },
];

export const requestTags: RequestTagType[] = [
  {
    chainId: ['0x1', '0x5', '0xaa36a7'],
    tag: 'eth',
  },
  {
    chainId: ['0x38', '0x61'],
    tag: 'bsc',
  },
  {
    chainId: ['0x833e3f'],
    tag: 'waterfall7',
  },
  {
    chainId: ['0x833e40'],
    tag: 'waterfall8',
  },
];

export const networks = ['waterfall7', 'waterfall8', 'bsc', 'eth'];

export const networksNames = {
  waterfall7: 'Waterfall TestNet 7',
  waterfall8: 'Waterfall TestNet 8',
  bsc: 'Binance',
  eth: 'Ethereum',
};

export const networksTags = {
  waterfall7: 'WATER',
  waterfall8: 'WATER',
  bsc: 'BNB',
  eth: 'ETH',
};

export const tagToChainId = {
  waterfall7: ['0x833e3f'],
  waterfall8: ['0x833e40'],
  bsc: ['0x38', '0x61'],
  eth: ['0x1', '0x5', '0xaa36a7'],
};
// Add token's name here if token name is different in networks
export const analogyToken = {
  WEETH: 'WETH',
  WBBNB: 'WBNB',
  WETH: 'WEETH',
  WBNB: 'WBBNB',
};
