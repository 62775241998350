import React from 'react';

const checkAmountValid = (value: number) => {
  return value > 0 && value < 10000;
};

export const validateBalance = (
  amount: string,
  balance: string,
  setValidTransfer: React.Dispatch<React.SetStateAction<boolean>>,
  setTransferError: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (checkAmountValid(Number(amount))) {
    if (Number(amount) <= Number(balance)) {
      setValidTransfer(true);
      setTransferError('');
    } else {
      setValidTransfer(false);
      setTransferError('Insufficient balance');
    }
  } else {
    setValidTransfer(false);
    Number(amount) > 10000 ? setTransferError('Wrong value') : '';
  }
};
