import {ChangeEventHandler, useEffect, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {address, chainId, error} from '../states/session';
import {analogyToken, requestTags} from '../constants/networks';
import {NETWORKS, ALLOWED_NETWORKS_FOR_TRANSFER, MAIN_NET, TOKENS, EXCHANGES} from '../constants/env';
import Web3 from 'web3';
import {gasAssessmentContract, getBalance, sendTransaction, switchNetwork} from './metamask';
import {errors} from '../constants/errors';
import {TokenType} from '../types/metamask';
import {PassTransactionType, ResponseBalance, ResponseGasFee, ResponseGetHash} from '../types/transaction';
import {validateBalance} from '../helper/validation';
import * as contract from '../constants/contract.json';
import {AbiItem} from 'web3-utils';
import {DECIMALS, GAS_FEE, API_URL} from '../constants/env';
import {getTransactionData} from './metamask';
import {useHistory} from 'react-router-dom';
import {route} from '../constants/route';

const getCurrentToken = (chainId: string, secondNet: string): TokenType | undefined => {
  const validTokens = TOKENS?.filter((el) => el.chainId.includes(secondNet));
  return TOKENS?.find(
    (el) =>
      el.chainId.includes(chainId) &&
      validTokens?.find(
        (el1) =>
          el1.symbol === el.symbol || analogyToken[el1.symbol] === el.symbol || el1.symbol === analogyToken[el.symbol],
      ),
  );
};

export const useTransfer = () => {
  const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');
  const [currentChainId, setChainId] = useRecoilState(chainId);
  const history = useHistory();
  const [errorState, setError] = useRecoilState(error);
  const [balance, setBalance] = useState<string>('');
  const userAddress = useRecoilValue(address);
  const [correctNet, setCorrectNet] = useState<string>('');
  const [translationtNet, setTranslationtNet] = useState<string>(
    NETWORKS?.find((el) => el?.chainId !== MAIN_NET?.chainId)?.chainId || '',
  );
  const [amount, setAmount] = useState<string>('0.00');
  const [transferValid, setValidTransfer] = useState<boolean>(false);
  const [transferError, setTransferError] = useState<string>('');
  const [selectToken, setToken] = useState<TokenType | undefined>(getCurrentToken(correctNet, translationtNet));
  const [viewTransactionMode, setViewMode] = useState<boolean>(false);
  const [passTransaction, setPassTransaction] = useState<PassTransactionType | null>(null);
  const [requestTrigger, setRequestTrigger] = useState<boolean>(false);
  const [tokenMenuStatus, setTokenMenuStatus] = useState<boolean>(false);
  const [networkMenuStatus, setNetMenuStatus] = useState<boolean>(false);
  const [confirmations, setConfirmations] = useState<number>(0);
  const tokenRef = useRef<HTMLHeadingElement>(null);
  const firstNetworkRef = useRef<HTMLHeadingElement>(null);
  const secondNetworkRef = useRef<HTMLHeadingElement>(null);

  const handlerChangeAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (/^\d+$/.test(e.target.value.replace('.', '')) || e.target.value === '') {
      setAmount(e.target.value);
    }
  };

  const handlerChangeToken = (token: TokenType) => {
    setToken(token);
  };

  const handlerSetNetwork = async (chainName: string, isFirstNet: boolean) => {
    setNetMenuStatus(false);

    if (isFirstNet) {
      const chainId = ALLOWED_NETWORKS_FOR_TRANSFER?.find((el) => el.chainName === chainName)?.chainId;
      if (!chainId) {
        return;
      }
      const statusTransaction = await switchNetwork(chainId);
      if (statusTransaction) {
        setChainId(chainId);
        setToken(getCurrentToken(chainId, translationtNet));
        setCorrectNet(chainId);
        setError('');
      } else {
        setError(errors.userReject);
      }
    } else {
      const chainId = ALLOWED_NETWORKS_FOR_TRANSFER?.find((el) => el.chainName === chainName)?.chainId;
      if (!chainId) {
        return;
      }
      setToken(getCurrentToken(chainId, translationtNet));
      setTranslationtNet(chainId);
    }
  };

  const changeBalance = async (address: string, token: TokenType) => {
    setBalance('0');
    const balance = await getBalance(address, token);
    if (history.location.pathname === route.base) {
      setBalance(balance);
    }
  };

  const treatmentChains = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: NETWORKS?.find((item) => !item.onlyRecive)?.chainId}],
      });
      return true;
    } catch (switchError: any) {
      if (switchError?.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [NETWORKS?.find((item) => !item.onlyRecive)],
          });
          return true;
        } catch (error) {
          return false;
        }
      }
      return false;
    }
  };

  const closeViewMode = () => {
    setPassTransaction(null);
    setAmount('0.00');
    setConfirmations(0);
    setViewMode(false);
  };

  const swapNetwork = async () => {
    const newNetwork = translationtNet;
    const requestNetwork = NETWORKS?.find((el) => el.chainId === newNetwork);
    if (!requestNetwork) {
      return;
    }
    const statusTransaction = await switchNetwork(requestNetwork.chainId);
    if (statusTransaction) {
      setChainId(newNetwork);
      setError('');
    } else {
      setError(errors.userReject);
    }
  };

  const transfer = async () => {
    if (userAddress && selectToken && currentChainId) {
      if (selectToken.isNative) {
        const currentBalance = await getBalance(userAddress, selectToken);
        if (Number(amount) > Number(currentBalance)) {
          setError(errors.notEnoughBalance);
          return;
        }
        const newProvider = NETWORKS?.find((el) => el?.chainId === translationtNet)?.rpcUrls?.[0] || '';
        if (!newProvider) {
          setError(errors.unknowError);
          return;
        }
        const result = await sendTransaction(
          {
            from: userAddress,
            chainId: currentChainId,
            to: selectToken.tokenAddress,
            value: web3.utils.toHex(web3.utils.toWei(amount, 'ether')),
          },
          setError,
        );
        if (typeof result === 'string') {
          setViewMode(true);
          setError('');
          setPassTransaction({
            sendHash: result,
            sendNetwork: currentChainId,
            receiveNetwork: translationtNet,
            receiveHash: '',
          });
        } else {
          setError(errors.unknowError);
        }
        return;
      }

      const exchange = EXCHANGES?.find((el) => el.chainId === correctNet)?.address || '';

      const exchangeNet =
        correctNet === MAIN_NET?.chainId
          ? requestTags.find((el) => el.chainId.includes(translationtNet))
          : requestTags.find((el) => el.chainId.includes(MAIN_NET?.chainId || ''));

      const userExchangeBalance: ResponseBalance | undefined = await getTransactionData(
        API_URL + `/account/${exchangeNet?.tag}/` + userAddress + '/balance',
      );
      const gasFeeWaterfall: ResponseGasFee | undefined = await getTransactionData(API_URL + `/tx/fees`);
      if (!userExchangeBalance || !gasFeeWaterfall || userExchangeBalance.balance === '0') {
        setError(errors.notEnoughBalance);
        return;
      }
      if (
        Number(userExchangeBalance.balance) / DECIMALS <
        (gasFeeWaterfall[exchangeNet?.tag || ''] * GAS_FEE) / DECIMALS
      ) {
        setError(errors.notEnoughBalance);
        return;
      }
      const mainContract = JSON.parse(JSON.stringify(contract)).default;
      const myContract = new web3.eth.Contract(mainContract as AbiItem[], selectToken.tokenAddress);
      const data = myContract.methods.transfer(exchange, web3.utils.toHex(web3.utils.toWei(amount))).encodeABI();
      setValidTransfer(false);
      const gasData = await gasAssessmentContract(
        data,
        userAddress,
        selectToken.tokenAddress,
        currentChainId,
        exchange,
        setError,
      );
      try {
        await myContract.methods.transfer(exchange, web3.utils.toHex(web3.utils.toWei(amount))).send(
          {
            from: userAddress,
            gasLimit: gasData.RawData.gasLimit,
            gasPrice: gasData.RawData.gasPrice,
          },
          async function (err: any, res: string) {
            if (err) {
              setError(err.message);
              setValidTransfer(true);
            }
            if (res) {
              setViewMode(true);
              setError('');
              setPassTransaction({
                sendHash: res,
                sendNetwork: currentChainId,
                receiveNetwork: translationtNet,
                receiveHash: '',
              });
            }
          },
        );
      } catch (e) {
        console.log(e);
        setError(e as any);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!tokenRef?.current?.contains(event.target)) {
        setTokenMenuStatus(false);
      }
      if (!firstNetworkRef?.current?.contains(event.target) && !secondNetworkRef?.current?.contains(event.target)) {
        setNetMenuStatus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tokenRef, firstNetworkRef, secondNetworkRef]);

  useEffect(() => {
    if (selectToken && userAddress && correctNet) {
      changeBalance(userAddress, selectToken);
    }
  }, [selectToken, userAddress]);

  useEffect(() => {
    if (
      currentChainId &&
      currentChainId !== correctNet &&
      translationtNet !== MAIN_NET?.chainId &&
      correctNet.length > 0
    ) {
      setTranslationtNet(correctNet);
      setToken(getCurrentToken(currentChainId, correctNet));
      setCorrectNet(currentChainId);
    } else if (currentChainId) {
      setCorrectNet(currentChainId);
      setTranslationtNet(
        currentChainId === MAIN_NET?.chainId
          ? NETWORKS?.find((el) => el?.chainId !== MAIN_NET?.chainId)?.chainId || ''
          : MAIN_NET?.chainId || '',
      );
    }
  }, [currentChainId]);

  useEffect(() => {
    if (currentChainId) {
      setToken(getCurrentToken(currentChainId, translationtNet));
    }
  }, [translationtNet]);

  useEffect(() => {
    validateBalance(amount, balance, setValidTransfer, setTransferError);
  }, [balance, amount]);

  useEffect(() => {
    let timoutId: NodeJS.Timeout;
    if (viewTransactionMode && history.location.pathname === route.base) {
      const link =
        API_URL +
        '/tx/' +
        (requestTags.find((el) => el.chainId.includes(passTransaction?.sendNetwork || ''))?.tag || '') +
        '/' +
        passTransaction?.sendHash;
      timoutId = setTimeout(async () => {
        const response: ResponseGetHash | null | string = passTransaction?.sendHash
          ? await getTransactionData(link)
          : null;
        if (history.location.pathname === route.base) {
          if (passTransaction?.sendHash && passTransaction?.sendHash.length) {
            const transaction = await web3.eth.getTransactionReceipt(passTransaction?.sendHash);
            const currentBlock = await web3.eth.getBlockNumber();
            const confirmationCount = transaction?.blockNumber ? currentBlock - transaction.blockNumber : 0;
            confirmationCount && confirmationCount !== confirmations ? setConfirmations(confirmationCount + 1) : null;
          }
          if (typeof response === 'string' || !response?.relatedTx) {
            setRequestTrigger(!requestTrigger);
          } else {
            setPassTransaction((prevState) => {
              if (prevState && response?.relatedTx?.hash) {
                return {
                  ...prevState,
                  receiveHash: response.relatedTx.hash,
                };
              }
              return null;
            });
          }
        }
      }, 10000);
    }
    () => {
      clearTimeout(timoutId);
    };
  }, [viewTransactionMode, requestTrigger, history]);

  return {
    firstNetwork: NETWORKS?.find((el) => el.chainId === currentChainId && !el?.onlyRecive),
    secondNetwork: NETWORKS?.find((el) => el.chainId === translationtNet),
    errorState,
    treatmentChains,
    swapNetwork,
    handlerChangeAmount,
    amount,
    transfer,
    handlerSetNetwork,
    selectToken,
    handlerChangeToken,
    transferError,
    closeViewMode,
    viewTransactionMode,
    passTransaction,
    balance,
    transferValid,
    tokenMenuStatus,
    tokenRef,
    setTokenMenuStatus,
    firstNetworkRef,
    secondNetworkRef,
    networkMenuStatus,
    setNetMenuStatus,
    confirmations,
  };
};
