import React, {ChangeEvent} from 'react';
import {
  Wrapper,
  NetworkBlock,
  SwapBlock,
  SwapButton,
  Title,
  NetworkRow,
  NetworkImageWrapper,
  WaterwallIcon,
  Input,
  TransferButton,
  SelectWrapper,
  SelectBlock,
  OptionsNetWrapper,
  Option,
  BalanceWrapper,
  InputWrapper,
  SelectToken,
  OptionToken,
  OptionsWrapper,
  OptionImage,
  DownArrow,
  ErrorTransferWrapper,
  Line,
  SimpleLink,
  ScaleWrapper,
} from './styles';
import ArrowDown from './assets/images/arrow-down.png';
import ArrowDownNet from './assets/images/arrow-down-net.png';
import {Text} from '../Typography/Text';
import {NetworkType, TokenType} from '../../types/metamask';
import {requestTags, analogyToken} from '../../constants/networks';
import {ErrorWrapper} from '../Login/styles';
import {networkIcons} from '../../constants/networks';
import {PassTransactionType} from '../../types/transaction';
import {ViewTransactionPage} from '../ViewTransactionPage';
import {errors} from '../../constants/errors';
import {route} from '../../constants/route';
import {MAIN_NET, ALLOWED_NETWORKS_FOR_TRANSFER, TOKENS, NETWORKS} from '../../constants/env';

interface HomeProps {
  amount: string;
  changeAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  changeNetwork: (e: string, isFirstNetwork: boolean) => void;
  selectToken: TokenType;
  firstNetwork: NetworkType;
  secondNetwork: NetworkType;
  swapNetwork: () => void;
  transfer: () => void;
  errorState: string | null;
  changeToken: (token: TokenType) => void;
  closeViewMode: () => void;
  balance: string;
  transferValid: boolean;
  viewTransactionMode: boolean;
  passTransaction: null | PassTransactionType;
  transferError: string;
  tokenRef: React.RefObject<HTMLHeadingElement>;
  tokenMenuStatus: boolean;
  tokenMenuSet: React.Dispatch<React.SetStateAction<boolean>>;
  firstNetworkRef: React.RefObject<HTMLHeadingElement>;
  secondNetworkRef: React.RefObject<HTMLHeadingElement>;
  networkMenuStatus: boolean;
  setNetMenuStatus: React.Dispatch<React.SetStateAction<boolean>>;
  confirmations: number;
}

export const HomePage: React.FC<HomeProps> = ({
  amount,
  changeAmount,
  firstNetwork,
  swapNetwork,
  secondNetwork,
  errorState,
  transfer,
  changeNetwork,
  selectToken,
  transferValid,
  changeToken,
  transferError,
  closeViewMode,
  viewTransactionMode,
  passTransaction,
  balance,
  tokenRef,
  tokenMenuSet,
  tokenMenuStatus,
  networkMenuStatus,
  firstNetworkRef,
  secondNetworkRef,
  setNetMenuStatus,
  confirmations,
}) => {
  const validTokens = TOKENS?.filter((el) => el.chainId.includes(secondNetwork.chainId));
  const firstIcon = networkIcons.find((el) => el.chainId.includes(firstNetwork.chainId))?.image;
  const secondIcon = networkIcons.find((el) => el.chainId.includes(secondNetwork.chainId))?.image;

  const handlerOpenMenu = (isNetworkMenu: boolean) => () => {
    isNetworkMenu ? setNetMenuStatus(!networkMenuStatus) : tokenMenuSet(!tokenMenuStatus);
  };

  const handlerChangeToken = (el: TokenType) => () => {
    changeToken(el);
    tokenMenuSet(false);
  };

  const handlerChangeNetwork = (chainName: string, isFirstNetwork: boolean) => () => {
    changeNetwork(chainName, isFirstNetwork);
  };

  return (
    <>
      {viewTransactionMode && passTransaction ? (
        <ViewTransactionPage
          amount={amount}
          selectToken={selectToken}
          firstNetwork={firstNetwork}
          secondNetwork={secondNetwork}
          swapNetwork={swapNetwork}
          errorState={errorState}
          closeViewMode={closeViewMode}
          passTransaction={passTransaction}
          confirmations={confirmations}
        />
      ) : (
        <>
          <Wrapper>
            <NetworkBlock>
              <Title>From</Title>
              <Line />
              <NetworkRow>
                <NetworkImageWrapper>{firstIcon ? <WaterwallIcon src={firstIcon} /> : null}</NetworkImageWrapper>
                {firstNetwork.chainId !== MAIN_NET?.chainId ? (
                  <SelectWrapper ref={firstNetworkRef}>
                    <SelectBlock onClick={handlerOpenMenu(true)}>
                      <div>{firstNetwork.chainName}</div>
                      <DownArrow src={ArrowDownNet} theme={{status: networkMenuStatus}} />
                    </SelectBlock>
                    <OptionsNetWrapper theme={{status: networkMenuStatus}}>
                      {ALLOWED_NETWORKS_FOR_TRANSFER?.filter(
                        (el) => !NETWORKS?.find((item) => el?.chainId === item?.chainId)?.onlyRecive,
                      ).map((el) => (
                        <Option onClick={handlerChangeNetwork(el.chainName, true)} key={el.chainId}>
                          {el.chainName}
                        </Option>
                      ))}
                    </OptionsNetWrapper>
                  </SelectWrapper>
                ) : (
                  <Text size={20}>{firstNetwork.chainName}</Text>
                )}
              </NetworkRow>
              <InputWrapper>
                <Input onChange={changeAmount} value={amount} placeholder={'0.00'} />
                <SelectToken ref={tokenRef}>
                  <OptionToken onClick={handlerOpenMenu(false)}>
                    <OptionImage src={selectToken.tokenImage} />
                    <Text size={18}>{selectToken.symbol}</Text>
                    <DownArrow src={ArrowDown} theme={{status: tokenMenuStatus}} />
                  </OptionToken>
                  <OptionsWrapper theme={{status: tokenMenuStatus}}>
                    {TOKENS?.map((el, index) => {
                      const tokenIsOnTheSecondNetwork = validTokens?.find(
                        (el1) =>
                          el1.symbol === el.symbol ||
                          analogyToken[el1.symbol] === el.symbol ||
                          el1.symbol === analogyToken[el.symbol],
                      );
                      if (
                        el.chainId.includes(firstNetwork.chainId) &&
                        el.tokenAddress.length > 0 &&
                        tokenIsOnTheSecondNetwork
                      ) {
                        return (
                          <OptionToken key={index} onClick={handlerChangeToken(el)}>
                            <OptionImage src={el.tokenImage} />
                            <Text size={18}>{el.symbol}</Text>
                          </OptionToken>
                        );
                      }
                    })}
                  </OptionsWrapper>
                </SelectToken>
              </InputWrapper>
              {transferError.length > 0 && <ErrorTransferWrapper>{transferError}</ErrorTransferWrapper>}
              <BalanceWrapper>
                <Text size={20} color="#2EEDFF">
                  Available balance:
                </Text>
                <Text size={20}>{balance + ' ' + selectToken.symbol}</Text>
              </BalanceWrapper>
            </NetworkBlock>
            <SwapBlock>
              <ScaleWrapper>
                <SwapButton
                  $isDisabled={secondNetwork.onlyRecive}
                  onClick={secondNetwork.onlyRecive ? undefined : swapNetwork}
                />
              </ScaleWrapper>
            </SwapBlock>
            <NetworkBlock>
              <Title>To</Title>
              <Line />
              <NetworkRow>
                <NetworkImageWrapper>{secondIcon ? <WaterwallIcon src={secondIcon} /> : null}</NetworkImageWrapper>
                {firstNetwork.chainId === MAIN_NET?.chainId ? (
                  <SelectWrapper ref={secondNetworkRef}>
                    <SelectBlock onClick={handlerOpenMenu(true)}>
                      <div>{secondNetwork.chainName}</div>
                      <DownArrow src={ArrowDownNet} theme={{status: networkMenuStatus}} />
                    </SelectBlock>
                    <OptionsNetWrapper theme={{status: networkMenuStatus}}>
                      {ALLOWED_NETWORKS_FOR_TRANSFER?.map((el) => (
                        <Option onClick={handlerChangeNetwork(el.chainName, false)} key={el.chainId}>
                          {el.chainName}
                        </Option>
                      ))}
                    </OptionsNetWrapper>
                  </SelectWrapper>
                ) : (
                  <Text size={20}>{secondNetwork.chainName}</Text>
                )}
              </NetworkRow>
            </NetworkBlock>
          </Wrapper>
          <ErrorWrapper theme={{status: errorState && errorState.length > 0}}>
            {errorState === errors.notEnoughBalance ? (
              <SimpleLink
                to={route.replenish.replace(
                  ':slug',
                  requestTags.find((el) => el.chainId.includes(secondNetwork.chainId))?.tag || '',
                )}>
                <Text>{errorState + '. Click Here'}</Text>
              </SimpleLink>
            ) : (
              errorState
            )}
          </ErrorWrapper>
          {(transferValid && <TransferButton type={'button'} value={'Transfer'} onClick={transfer} />) || (
            <TransferButton type={'button'} value={'Transfer'} onClick={transfer} disabled />
          )}
        </>
      )}
    </>
  );
};
