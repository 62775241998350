import styled, {css, StyledProps} from 'styled-components';
import {Link, LinkProps} from 'react-router-dom';
import {TextBaseProps} from './Text';

const color = ({color: colorVariant}: StyledProps<TextBaseProps>) => {
  switch (colorVariant) {
    case 'white':
      return 'rgba(255, 255, 255, 0.87)';
    case 'blue':
      return '#55BAFF';
    case 'black':
      return '#000';
    default:
      return colorVariant || 'rgba(255, 255, 255, 0.87)';
  }
};

const fontFamily = ({variant}: StyledProps<TextBaseProps>) => {
  switch (variant) {
    case 'primary':
      return 'Roboto';
    case 'secondary':
      return 'sans-serif';
    default:
      return 'Roboto, sans-serif';
  }
};

const textAlign = ({align}: StyledProps<TextBaseProps>) => {
  switch (align) {
    case 'left':
      return 'text-align: left;';
    case 'right':
      return 'text-align: right;';
    case 'center':
      return `
      text-align: center;
      display: block`;
    default:
      return 'Roboto';
  }
};

const fontSize = ({size}: TextBaseProps) => `${size ? `${size}px` : '14px'}`;

const fontWeight = ({weight}: TextBaseProps) => {
  switch (weight) {
    case 'bold':
      return 700;
    case 'semi-bold':
      return 600;
    case 'medium':
      return 500;
    case 'regular':
      return 400;
    default:
      return 400;
  }
};

const fontCase = ({case: _case}: TextBaseProps) => _case || 'none';

const textDecoration = ({decoration}: TextBaseProps) => decoration || 'none';

const styles = css`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  text-decoration: ${textDecoration};
  text-transform: ${fontCase};
  color: ${color};
  ${textAlign};
`;

export const StyledText = styled.span<TextBaseProps>`
  ${styles};
`;

export const StyledLink = styled(Link)<TextBaseProps & LinkProps>`
  ${styles};
`;
