import React from 'react';
import {
  Wrapper,
  NetworkBlock,
  SwapBlock,
  SwapButton,
  Title,
  NetworkRow,
  NetworkImageWrapper,
  WaterwallIcon,
  BalanceWrapper,
  InputWrapper,
  ViewModeTokenImage,
  LinkToHash,
  LoadingButton,
  TopBlock,
  BackButton,
  Line,
  ViewModeAmountWrapper,
  ScaleWrapper,
} from '../Home/styles';
import {Text} from '../Typography/Text';
import {NetworkType, TokenType} from '../../types/metamask';
import {NETWORKS} from '../../constants/env';
import {ErrorWrapper} from '../Login/styles';
import {networkIcons} from '../../constants/networks';
import {PassTransactionType} from '../../types/transaction';
import {lineShortening} from '../../helper/stringHelper';
import {ReactComponent as ArrowLink} from '../Home/assets/images/arrowLink.svg';

interface ViewModeProps {
  amount: string;
  selectToken: TokenType;
  firstNetwork: NetworkType;
  secondNetwork: NetworkType;
  errorState: string | null;
  closeViewMode: () => void;
  passTransaction: PassTransactionType;
  swapNetwork: () => void;
  confirmations: number;
}

export const ViewTransactionPage: React.FC<ViewModeProps> = ({
  amount,
  firstNetwork,
  secondNetwork,
  errorState,
  selectToken,
  closeViewMode,
  swapNetwork,
  passTransaction,
  confirmations,
}) => {
  const firstIcon = networkIcons.find((el) => el.chainId.includes(firstNetwork.chainId))?.image;
  const secondIcon = networkIcons.find((el) => el.chainId.includes(secondNetwork.chainId))?.image;
  const transactionBack = () => {
    closeViewMode();
    swapNetwork();
  };
  return (
    <>
      <TopBlock>
        <BackButton onClick={closeViewMode}>
          <Text size={18}>Back</Text>
        </BackButton>
      </TopBlock>
      <Wrapper>
        <NetworkBlock>
          <Title>From</Title>
          <Line />
          <NetworkRow>
            <NetworkImageWrapper>{firstIcon ? <WaterwallIcon src={firstIcon} /> : null}</NetworkImageWrapper>
            <Text size={20}>{firstNetwork.chainName}</Text>
          </NetworkRow>
          <InputWrapper>
            <Text size={24}>
              {passTransaction.sendHash.length > 0 ? lineShortening(passTransaction.sendHash) : null}
            </Text>
            <Text size={24}>
              {passTransaction.sendHash.length > 0 ? (
                <LinkToHash
                  href={
                    (NETWORKS
                      ? NETWORKS.find((el) => el.chainId === passTransaction?.sendNetwork)?.blockExplorerUrls[0]
                      : '') +
                    '/tx/' +
                    passTransaction.sendHash
                  }
                  target={'_blank'}>
                  <ArrowLink />
                </LinkToHash>
              ) : null}
            </Text>
          </InputWrapper>
          <BalanceWrapper>
            <Text size={20} color="#2EEDFF">
              Confirmations:
            </Text>
            <Text size={20}>{confirmations}</Text>
          </BalanceWrapper>
        </NetworkBlock>
        <SwapBlock>
          <ScaleWrapper>
            {passTransaction.receiveHash.length === 0 ? (
              <LoadingButton />
            ) : (
              <SwapButton
                $isDisabled={secondNetwork.onlyRecive}
                onClick={secondNetwork.onlyRecive ? undefined : transactionBack}
                $margin
              />
            )}
          </ScaleWrapper>
          <Text size={16}>{amount}</Text>
          <ViewModeAmountWrapper>
            <ViewModeTokenImage src={selectToken.tokenImage} />
            <Text size={18}>{selectToken.symbol}</Text>
          </ViewModeAmountWrapper>
        </SwapBlock>
        <NetworkBlock>
          <Title>To</Title>
          <Line />
          <NetworkRow>
            <NetworkImageWrapper>{secondIcon ? <WaterwallIcon src={secondIcon} /> : null}</NetworkImageWrapper>
            <Text size={20}>{secondNetwork.chainName}</Text>
          </NetworkRow>
          <InputWrapper>
            <Text size={24}>
              {passTransaction.receiveHash.length > 0 ? lineShortening(passTransaction.receiveHash) : 'loading...'}
            </Text>
            <Text size={24}>
              {passTransaction.receiveHash.length > 0 ? (
                <LinkToHash
                  href={
                    (NETWORKS
                      ? NETWORKS.find((el) => el.chainId === passTransaction?.receiveNetwork)?.blockExplorerUrls[0]
                      : '') +
                    '/tx/' +
                    passTransaction.receiveHash
                  }
                  target={'_blank'}>
                  <ArrowLink />
                </LinkToHash>
              ) : null}
            </Text>
          </InputWrapper>
        </NetworkBlock>
      </Wrapper>
      <ErrorWrapper theme={{status: errorState && errorState.length > 0}}>{errorState}</ErrorWrapper>
    </>
  );
};
