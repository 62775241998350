import React, {ChangeEvent} from 'react';
import {
  Wrapper,
  TitleCentre,
  NetworkRow,
  NetworkImageWrapper,
  WaterwallIcon,
  Input,
  TransferButton,
  BalanceWrapper,
  InputWrapper,
  SelectToken,
  OptionToken,
  OptionImage,
  ErrorTransferWrapper,
  Line,
  TopBlock,
  BackButton,
  Title,
  LinkToHash,
  ScaleWrapper,
  SimpleLink,
} from '../Home/styles';
import {
  SwapBlockExchange,
  SwapButtonExchange,
  LoadingButtonExchange,
  TransactionStatusWrapper,
  ViewModeAmountWrapperExchange,
  ViewModeTokenImageExchange,
  NetworkBlockRP,
} from './styles';
import {Text} from '../Typography/Text';
import {NetworkType, TokenType} from '../../types/metamask';
import {ErrorWrapper} from '../Login/styles';
import {networkIcons, networksNames} from '../../constants/networks';
import {PassTransactionType} from '../../types/transaction';
import {lineShortening} from '../../helper/stringHelper';
import {ReactComponent as ArrowLink} from '../Home/assets/images/arrowLink.svg';
import {route} from '../../constants/route';
import {NETWORKS} from '../../constants/env';

interface ReplenishmentPageProps {
  amount: string;
  changeAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  selectToken: TokenType;
  firstNetwork: NetworkType;
  transfer: () => void;
  errorState: string | null;
  closeViewMode: () => void;
  transferValid: boolean;
  viewTransactionMode: boolean;
  passTransaction: null | PassTransactionType;
  transferError: string;
  confirmations: number;
  exchangeName: string;
  treatmentChains: () => void;
  balance: string;
  networkIsCorrect: boolean;
  slug: string;
}

export const ReplenishmentPage: React.FC<ReplenishmentPageProps> = ({
  confirmations,
  transferValid,
  transfer,
  errorState,
  amount,
  balance,
  changeAmount,
  closeViewMode,
  transferError,
  selectToken,
  firstNetwork,
  viewTransactionMode,
  passTransaction,
  exchangeName,
  treatmentChains,
  networkIsCorrect,
  slug,
}) => {
  const firstIcon = networkIcons.find((el) => el.chainId.includes(firstNetwork.chainId))?.image;
  return (
    <>
      {viewTransactionMode && passTransaction ? (
        <>
          <TopBlock>
            <BackButton onClick={closeViewMode}>
              <Text size={18}>Back</Text>
            </BackButton>
          </TopBlock>
          <Wrapper theme={{vertical: true}}>
            <NetworkBlockRP>
              <Title>Replenishment</Title>
              <Line />
              <NetworkRow>
                <NetworkImageWrapper>{firstIcon ? <WaterwallIcon src={firstIcon} /> : null}</NetworkImageWrapper>
                <Text size={20}>{firstNetwork.chainName}</Text>
              </NetworkRow>
              <InputWrapper>
                <Text size={24}>
                  {passTransaction.sendHash.length > 0 ? lineShortening(passTransaction.sendHash) : null}
                </Text>
                <Text size={24}>
                  {passTransaction.sendHash.length > 0 ? (
                    <LinkToHash
                      href={
                        (NETWORKS?.find((el) => el.chainId === passTransaction?.sendNetwork)?.blockExplorerUrls[0] ||
                          '') +
                        '/tx/' +
                        passTransaction.sendHash
                      }
                      target={'_blank'}>
                      <ArrowLink />
                    </LinkToHash>
                  ) : null}
                </Text>
              </InputWrapper>
              <BalanceWrapper>
                <Text size={20} color="#2EEDFF">
                  Confirmations:
                </Text>
                <Text size={20}>{confirmations}</Text>
              </BalanceWrapper>
            </NetworkBlockRP>
            <SwapBlockExchange>
              <>
                <ViewModeAmountWrapperExchange>
                  <ScaleWrapper>
                    {confirmations < 9 ? (
                      <LoadingButtonExchange />
                    ) : (
                      <SimpleLink to={route.base}>
                        <SwapButtonExchange />
                      </SimpleLink>
                    )}
                  </ScaleWrapper>
                  <Text size={16}>{amount}</Text>
                  <ViewModeTokenImageExchange src={selectToken.tokenImage} />
                  <Text size={18}>{selectToken.symbol}</Text>
                </ViewModeAmountWrapperExchange>
              </>
              <TransactionStatusWrapper>
                <Text size={20}>Status:</Text>
                {confirmations > 9 ? (
                  <Text size={20} color={'#78CF79'}>
                    {'success'}
                  </Text>
                ) : (
                  <Text size={20}>{'...Loading'}</Text>
                )}
              </TransactionStatusWrapper>
            </SwapBlockExchange>
          </Wrapper>
        </>
      ) : (
        <>
          <Wrapper>
            <NetworkBlockRP>
              <TitleCentre>{`Replenishment of the exchange ${exchangeName}`}</TitleCentre>
              <Line />
              <NetworkRow>
                <NetworkImageWrapper>{firstIcon ? <WaterwallIcon src={firstIcon} /> : null}</NetworkImageWrapper>
                <Text size={20}>{firstNetwork.chainName}</Text>
              </NetworkRow>
              <InputWrapper>
                <Input onChange={changeAmount} value={amount} placeholder={'0.00'} />
                <SelectToken>
                  <OptionToken>
                    <OptionImage src={selectToken?.tokenImage} />
                    <Text size={18}>{selectToken?.symbol}</Text>
                  </OptionToken>
                </SelectToken>
              </InputWrapper>
              {transferError.length > 0 && <ErrorTransferWrapper>{transferError}</ErrorTransferWrapper>}
              <BalanceWrapper>
                <Text size={20} color="#2EEDFF">
                  Available balance:
                </Text>
                <Text size={20}>{balance + ' ' + selectToken?.symbol}</Text>
              </BalanceWrapper>
            </NetworkBlockRP>
          </Wrapper>
          <ErrorWrapper theme={{status: errorState && errorState.length > 0}}>{errorState}</ErrorWrapper>
          {(!networkIsCorrect && (
            <TransferButton
              type={'button'}
              value={`Switch to ${networksNames[slug]} network`}
              onClick={treatmentChains}
            />
          )) ||
            (transferValid && <TransferButton type={'button'} value={'Transfer'} onClick={transfer} />) || (
              <TransferButton type={'button'} value={'Transfer'} onClick={transfer} disabled />
            )}
        </>
      )}
    </>
  );
};
