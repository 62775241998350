import styled from 'styled-components';
import {Text} from '../Typography/Text';

export const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  background-color: #233848;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text).attrs({
  color: 'blue',
  size: 24,
})`
  display: inline-block;
  margin-bottom: 40px;
  @media (max-width: 425px) {
    margin-bottom: 20px;
  }
`;

export const Content = styled(Text)`
  padding: 0 60px;
  width: calc(100% - 120px);
`;

export const ContentMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  margin: 0;
`;

export const Footer = styled.footer`
  display: flex;
  position: fixed;
  text-align: center;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: #102636;
`;
