import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  width: 400px;
  background-color: rgb(39, 63, 82);
  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const Metamask = styled.img`
  height: 256px;
  width: 256px;
  margin-bottom: 30px;
`;

export const LoginButton = styled.input`
  display: flex;
  align-items: center;
  background: rgb(45, 69, 87);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 32px;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 5px;
  border: none;
  margin-bottom: 30px;
  transition: background-color 0.5s ease;
  outline: none;

  &:hover {
    background-color: #55baff;
  }
  &:active {
    background-color: rgba(16, 38, 54, 0.6);
  }
`;

export const ErrorWrapper = styled.div`
  display: ${(props) => (props.theme.status ? 'flex' : 'none')};
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: red;
  padding: 10px 25px;
  width: 350px;
  background: rgb(25, 47, 64);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 480px) {
    width: 80%;
    padding: 10px 5%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  text-underline: none;
`;
