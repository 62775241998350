import React, {useEffect, useState} from 'react';
import {RecoilRoot} from 'recoil';
import {session} from '../states/session';
import {useHistory} from 'react-router-dom';
import {useCheckConnect} from '../hooks/auth';
import {route} from '../constants/route';
import {checkChain} from '../hooks/metamask';
import {networks} from '../constants/networks';

const RecoilProvider: React.FC = ({children}) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [address, setAddress] = useState<string>('');
  const [chainId, setChainId] = useState<string>('');

  useEffect(() => {
    checkChain().then((result) => {
      setChainId(result ? result : '');
      useCheckConnect().then((address) => {
        setAddress(address);
        setLoading(false);
        if (address.length === 0) {
          history.push(route.login);
        } else {
          history.push(route.base);
        }
      });
    });
  }, []);

  if (loading) return null;
  return (
    <RecoilRoot
      initializeState={({set}) => {
        set(session, {
          error: '',
          address: address,
          chainId: chainId,
          balances: networks.map((el) => {
            return {
              balance: '0',
              network: el,
              address: address,
            };
          }),
        });
      }}>
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;
