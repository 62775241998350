import MetaMaskOnboarding from '@metamask/onboarding';
import {CHAID_ID} from '../constants/env';
import {ALLOWED_NETWORKS_CHAIN_ID} from '../constants/env';

export const checkChainValid = (chainId: string) => {
  return chainId == CHAID_ID;
};

export const checkAllowedChain = (chainId: string) => {
  return ALLOWED_NETWORKS_CHAIN_ID?.includes(chainId);
};
export const checkMetaMask = () => {
  return !!window.ethereum?.request && MetaMaskOnboarding.isMetaMaskInstalled() && !!window.ethereum?.on;
};
