import React from 'react';
import {MainLayout} from '../../components/Layout';
import {useLayout} from '../../hooks/auth';

export const LayoutContainer = ({children}) => {
  const {balances, userAddress, menuRef, menuStatus, setMenuStatus} = useLayout();
  return (
    <MainLayout
      userAddress={userAddress}
      balances={balances}
      setMenuStatus={setMenuStatus}
      menuStatus={menuStatus}
      menuRef={menuRef}>
      {children}
    </MainLayout>
  );
};
