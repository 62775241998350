import {useEffect, useRef, useState} from 'react';
import {checkAllowedChain, checkChainValid, checkMetaMask} from '../helper/metamask';
import {MAIN_NET} from '../constants/env';
import {errors} from '../constants/errors';
import {getAccounts, checkChain} from './metamask';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {address, error, chainId, balances} from '../states/session';

export const useCheckConnect = async () => {
  const chainId = await checkChain();
  if (!chainId) {
    return '';
  }
  if (!checkMetaMask()) {
    return '';
  }
  if (!checkAllowedChain(chainId)) {
    return '';
  }
  return await getAccounts();
};

export const useLogin = () => {
  const [activeAddress, setActiveAddress] = useRecoilState(address);
  const [errorState, setError] = useRecoilState(error);
  const setChainId = useSetRecoilState(chainId);

  const treatmentChains = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: MAIN_NET?.chainId}],
      });
      return true;
    } catch (switchError: any) {
      if (switchError?.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [MAIN_NET],
          });
          return true;
        } catch (error) {
          return false;
        }
      }
      return false;
    }
  };

  const requestAccounts = async (): Promise<string> => {
    if (!checkMetaMask()) {
      return errors.metamask;
    }
    const metamaskNetwork = await checkChain();
    if (!metamaskNetwork) {
      return errors.metamask;
    }
    if (!checkChainValid(metamaskNetwork)) {
      return errors.chain;
    }
    setChainId(metamaskNetwork);
    try {
      let userAddress = activeAddress;
      const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      if (!userAddress && accounts.length) {
        setActiveAddress(accounts[0]);
        userAddress = accounts[0];
      }
      if (accounts) {
        if (accounts.length && userAddress && userAddress.length) {
          return '';
        } else {
          return 'Check your network connection';
        }
      } else {
        return errors.userReject;
      }
    } catch (e: any) {
      return e?.message || errors.userReject;
    }
  };
  return {
    requestAccounts,
    treatmentChains,
    activeAddress,
    setError,
    errorState,
  };
};

export const useLayout = () => {
  const userAddress = useRecoilValue(address);
  const balancesState = useRecoilValue(balances);
  const menuRef = useRef<HTMLHeadingElement>(null);
  const [menuStatus, setMenuStatus] = useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setMenuStatus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);
  return {
    userAddress,
    menuRef,
    menuStatus,
    setMenuStatus,
    balances: balancesState,
  };
};
