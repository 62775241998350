import styled from 'styled-components';
import {Link} from '../Typography/Link';

export const Container = styled.div`
  max-width: 1795px;
  height: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Wrapper = styled.header`
  width: 100%;
  height: 70px;
  background-color: #102636;
  margin-bottom: 60px;
`;

export const Filling = styled.div`
  max-width: 1155px;
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 16px;

  &:hover {
    & span {
      text-decoration: underline;
    }
  }

  @media (max-width: 560px) {
    :first-child {
      margin-right: 16px;
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 650px) {
    justify-content: center;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #233848;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  height: 40px;
  margin-right: 50px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: calc(100% - 39px);
  align-items: center;
  height: 40px;
  padding: 0 15px 0 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: rgba(48, 76, 98, 1);
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  border: none;
  font-weight: 400;
`;

export const DownArrow = styled.img`
  width: 15px;
  height: 8px;
  margin-left: 10px;
  transform: ${(props) => (props.theme.status ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const OptionsNetWrapper = styled.div`
  display: ${(props) => (props.theme.status ? 'flex' : 'none')};
  position: absolute;
  background: rgba(44, 70, 91, 0.59);
  border-radius: 20px;
  flex-direction: column;
  padding-top: 37px;
  top: 10px;
  width: 100%;
  z-index: 1;
  & > div:last-child {
    &:hover {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: 15px;
  width: calc(100% - 48px);
  line-height: 22px;
  font-weight: 400;
  height: 100%;
  padding-left: 24px;
  padding-bottom: 7px;
  padding-right: 24px;
  &:hover {
    background: rgba(44, 70, 91, 1);
  }
`;
