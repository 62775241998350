import {NetworkType, TokenType, ExchangeType} from '../types/metamask';

declare global {
  interface Window {
    _env_: {
      REACT_APP_API_URL: string | undefined;
      REACT_APP_NETWORKS: NetworkType[] | undefined;
      REACT_APP_ALLOWED_NETWORKS_FOR_TRANSFER: NetworkType[] | undefined;
      REACT_APP_TOKENS: TokenType[] | undefined;
      REACT_APP_EXCHANGE: ExchangeType[] | undefined;
      REACT_APP_MAIN_NET: NetworkType | undefined;
    };
  }
}
export const API_URL =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_API_URL
    ? window._env_.REACT_APP_API_URL
    : null;

export const NETWORKS: NetworkType[] | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_NETWORKS
    ? window._env_.REACT_APP_NETWORKS
    : null;

export const ALLOWED_NETWORKS_CHAIN_ID = NETWORKS ? NETWORKS.map((el) => el.chainId) : null;

export const ALLOWED_NETWORKS_FOR_TRANSFER: NetworkType[] | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_ALLOWED_NETWORKS_FOR_TRANSFER
    ? window._env_.REACT_APP_ALLOWED_NETWORKS_FOR_TRANSFER
    : null;

export const TOKENS: TokenType[] | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_TOKENS ? window._env_.REACT_APP_TOKENS : null;

export const EXCHANGES: ExchangeType[] | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_EXCHANGE
    ? window._env_.REACT_APP_EXCHANGE
    : null;

export const MAIN_NET: NetworkType | null =
  typeof window !== 'undefined' && window._env_ && window._env_.REACT_APP_MAIN_NET
    ? window._env_.REACT_APP_MAIN_NET
    : null;

export const CHAID_ID = MAIN_NET?.chainId;
export const GAS_FEE = 0.02;
export const DECIMALS = Math.pow(10, 18);
export const BALANCE_DELAY = 30000;
