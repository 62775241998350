import {atom, selector} from 'recoil';
import {BalancesType, SessionType} from '../types/session';

export type SessionStateType = SessionType | null | undefined;
export const session = atom<SessionStateType>({
  key: 'session',
  default: undefined,
});

export const address = selector<string | null>({
  key: 'address',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.address;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, address: newValue});
  },
});

export const error = selector<string | null>({
  key: 'error',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.error;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, error: newValue});
  },
});

export const chainId = selector<string | null>({
  key: 'chainId',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.chainId;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (typeof newValue !== 'string') {
      return s;
    }
    set(session, {...s, chainId: newValue});
  },
});

export const balances = selector<BalancesType[] | null>({
  key: 'balances',
  get: ({get}) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    return s.balances;
  },
  set: ({set, get}, newValue) => {
    const s = get(session);
    if (!s) {
      return null;
    }
    if (newValue === null || !Array.isArray(newValue)) {
      return s;
    }
    if (newValue[0] && newValue[0].address !== s.address) {
      return s;
    }
    set(session, {
      ...s,
      balances:
        s.balances?.map((el) => (newValue[0] && el?.network === newValue[0].network ? newValue[0] : el)) || null,
    });
  },
});
