import React from 'react';
import {Container, ContentMain, Title, Footer, Content} from './styles';
import {Header} from '../Header';
import {BalancesType} from '../../types/session';

interface Props {
  title?: string;
  userAddress: string | null;
  menuStatus: boolean;
  setMenuStatus: React.Dispatch<React.SetStateAction<boolean>>;
  menuRef: React.RefObject<HTMLHeadingElement>;
  balances: BalancesType[] | null;
}

export const MainLayout: React.FC<Props> = ({
  children,
  title,
  userAddress,
  balances,
  setMenuStatus,
  menuStatus,
  menuRef,
}) => {
  return (
    <>
      <Container>
        <Header
          userAddress={userAddress}
          balances={balances}
          menuRef={menuRef}
          menuStatus={menuStatus}
          setMenuStatus={setMenuStatus}
        />
        <ContentMain>
          {title && <Title>{title}</Title>}
          {children}
        </ContentMain>
      </Container>
      <Footer>
        <Content>
          This site is for demo and testing purposes only and this technology is still in a test mode and needs more
          profound elaboration to be released as a ready-made product.
        </Content>
      </Footer>
    </>
  );
};
