import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ReactComponent as SwapImage} from '../Home/assets/images/swapArrows.svg';
import {NetworkBlock} from '../Home/styles';

export const SimpleLink = styled(Link)`
  text-decoration: none;
  &:hover {
    opacity: 0.65;
  }
`;

export const NetworkBlockRP = styled(NetworkBlock)`
  @media (max-width: 1400px) {
    width: 476px;
  }
`;

export const SwapBlockExchange = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
  border-radius: 20px;
  width: 544px;
  padding: 20px;
  height: 70px;
  background-color: rgb(45, 69, 87);
`;

export const LoadingButtonExchange = styled(SwapImage)`
  cursor: pointer;
  transition: all 0.5s ease;
  height: 50px;
  margin-right: 10px;
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
  }
`;

export const SwapButtonExchange = styled(SwapImage)`
  cursor: pointer;
  height: 50px;
  transition: transform 0.5s;
  &:hover {
    transform: rotate(-180deg);
  }
`;

export const ViewModeAmountWrapperExchange = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const ViewModeTokenImageExchange = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-left: 10px;
`;

export const TransactionStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 290px;
  justify-content: space-between;
  align-items: flex-start;
  :& > span:last-child { {
    margin-right: 10px;
  }
`;
